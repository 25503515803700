import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import dotImage from "../../Assets/dots.png";
import Cookies from "js-cookie";
import { Form, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { clearQrState } from "../../Redux/Reducers/qrListReducer";
import { QrList } from "../../Redux/Actions/qrList";
import { useTranslation } from "react-i18next";
import ChatbotModal from "../Chatbot/ChatbotModal";
import { Modal } from "react-bootstrap";
import { myTribute } from "../../Redux/Actions/myTribute";
import { QrAuthentication, getQrDetail } from "../../Redux/Actions/auth";

const Sidebar = ({ handleClose }) => {
  const isAuthenticated = Cookies.get("isAuthenticated");
  const userToken = Cookies.get("token");

  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const tributeData = useSelector((state) => state.myTribute.data);
  const qrDetail = useSelector((state) => state.user.qrData);

  const [showChatBot, setShowChatBot] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const handleShowChatBot = () => setShowChatBot(true);
  const handleCloseChatBot = () => setShowChatBot(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState("");

  const handleLogout = () => {
    Cookies.remove("token");
    message.success(t("loggedOutSuccessfully"));
    dispatch(clearQrState());
  };
  useEffect(() => {
    dispatch(getQrDetail(id));
  }, [dispatch]);
  const changeLanguage = async (value) => {
    i18n.changeLanguage(value);
    await localStorage.setItem("lang", value);
    setLanguage(value);
    navigate(`/pages/bio/${id}`);
    handleClose();
  };

  const handleNavigate = (id) => {
    navigate(`/pages/bio/${id}`);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const { data } = useSelector((state) => state.userqr);
  useEffect(() => {
    dispatch(QrList());
  }, [dispatch]);

  useEffect(() => {
    const value = localStorage.getItem("lang");
    if (value) {
      setLanguage(value);
    } else {
      setLanguage("en");
    }
  }, []);

  useEffect(() => {
    dispatch(myTribute());
  }, [dispatch]);

  useEffect(() => {
    dispatch(QrAuthentication(id));
  }, []);

  return (
    <Fragment>
      <div className="container-fluid sidebarQRR">
        <div className="row">
          <div className="col-sm-12 qrListSidebar"></div>
          <div className="col-sm-12 lisst">
            {userToken && (
              <ul>
                <li onClick={toggleDropdown} className="noMb">
                  <NavLink
                    to="/pages"
                    activeClassName="active"
                    className={
                      data && data?.length > 0 ? "dropdown-toggle" : ""
                    }
                  >
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        {t("myProfiles")}{" "}
                        {data && data?.length > 0 ? (
                          <>({data?.length})</>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </NavLink>
                </li>
                {showDropdown ? (
                  <div style={{ maxHeight: "270px", overflow: "scroll" }}>
                    {data && data?.length > 0 ? (
                      data?.map((item) => (
                        <li
                          onClick={() => handleNavigate(item?.QrId)}
                          className="sidebarQRSListing"
                        >
                          {item?.UserQr?.QrProfileData?.Name &&
                          item?.UserQr?.QrProfileData?.ProfilePic ? (
                            <>
                              <img
                                src={item.UserQr?.QrProfileData?.ProfilePic}
                                alt="profile"
                              />
                              <p>{item.UserQr?.QrProfileData?.Name}</p>
                            </>
                          ) : (
                            <>
                              <img src={item.UserQr?.QrMedia} alt="qrcode" />
                              <p>{t("noData")}</p>
                            </>
                          )}
                        </li>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {tributeData &&
                tributeData?.items &&
                tributeData?.items?.length &&
                tributeData?.items?.length > 0 ? (
                  <li className="yesMB">
                    <NavLink to="/my-tributes" activeClassName="active">
                      {({ isActive }) => (
                        <>
                          {isActive && (
                            <img
                              src={dotImage}
                              alt="Active indicator"
                              className="active-indicator"
                            />
                          )}
                          {t("myTributes")}
                        </>
                      )}
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}

                <li className={tributeData?.items?.length > 0 ? "" : "yesMB"}>
                  <NavLink to="/profile" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        {t("profile")}
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faq" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        {t("faq")}
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="https://legacyhonored.com/"
                    activeClassName="active"
                    target="_blank"
                  >
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        {t("learnMore")}
                      </>
                    )}
                  </NavLink>
                </li>
                {/* <li>
                  <a onClick={handleShowChatBot} className="contaacus">
                    {t("contactUs")}
                  </a>
                </li> */}
                <li>
                  <NavLink
                    to="/"
                    activeClassName="active"
                    onClick={handleLogout}
                  >
                    {t("logout")}
                  </NavLink>
                </li>
              </ul>
            )}
            {!userToken && (
              <ul>
                <li
                  onClick={() => handleNavigate(localStorage.getItem("ids"))}
                  className="sidebarQRSListing"
                >
                  {qrDetail?.UserDetail?.Name ? (
                    <>
                      <img
                        src={qrDetail?.UserDetail?.ProfilePic}
                        alt="profile"
                      />
                      <p>{qrDetail?.UserDetail?.Name} </p>
                    </>
                  ) : (
                    <></>
                  )}
                </li>
                <li className="yesMB">
                  <NavLink to="/faq" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        {t("faq")}
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="https://graphicsense.co.uk/legacyhonored/"
                    activeClassName="active"
                    target="_blank"
                  >
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        {t("learnMore")}
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        {t("Login / Sign Up")}
                      </>
                    )}
                  </NavLink>
                </li>
                {/* <li>
                <a onClick={handleShowChatBot} className="contaacus">
                  {t("contactUs")}
                </a>
              </li> */}
              </ul>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 lisst language">
            <ul>
              <li className="LanguageDropdown">
                <Form>
                  <Form.Item label="Language">
                    <Select onChange={changeLanguage} value={language}>
                      <Select.Option value="en">English</Select.Option>
                      <Select.Option value="fr">Français</Select.Option>
                      <Select.Option value="es">Español</Select.Option>
                      <Select.Option value="nl">Deutsch</Select.Option>
                    </Select>
                  </Form.Item>
                </Form>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal show={showChatBot} onHide={handleCloseChatBot}>
        <Modal.Header closeButton></Modal.Header>
        <ChatbotModal />
      </Modal>
    </Fragment>
  );
};

export default Sidebar;
