import { Button, Image, Tooltip, message } from 'antd'
import React, { Fragment, useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal } from "react-bootstrap";
import UploadPhotoVideoModal from "./UploadPhotoVideoModal";
import EditVideoModal from "./EditVideoModal";
import DeleteModal from "../../DeleteModal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteMedia, getmedia } from "../../../Redux/Actions/media";
import Pagination from "../../Pagination/Pagination";
import { clearState } from "../../../Redux/Reducers/mediaReducer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Cookies from "js-cookie";
import DescriptionModal from './DescriptionModal';


const Photoss = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const { data } = useSelector((state) => state.media);
  const [showEdit, setShowEdit] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [descriptionData, setDescriptionData] = useState('')
  const handleShowEdit = (data) => {
    setEditData(data);
    setShowEdit(true);
  };

  const handleShowDescription = (data) => {
    setShowDescription(true);
    setDescriptionData(data);
  }
  const handleCloseDescription = () => {
    setShowDescription(false);
  }
  const isAuthenticated = Cookies.get("isAuthenticated")

  const handleCloseEdit = () => {
    dispatch(getmedia({ id, page, pageSize }));

    setShowEdit(false);
  };
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [ids, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState();
  const { deleteError, deleteSuccess } = useSelector((state) => state.media);

  const handleDelete = async (ids) => {
    await dispatch(deleteMedia(ids));
    await dispatch(getmedia({ id, page, pageSize }));
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleModalOpen = (id) => {
    setId(id);
    setModalOpen(!modalOpen);
  };
  function getEmbedLink(videoUrl) {
    try {
      // Extracting the video ID from the URL
      const url = new URL(videoUrl);
      let embedLink = "";

      if (url.host === "youtu.be" || url.host === "www.youtube.com") {
        // For YouTube
        const videoId =
          url.host === "youtu.be"
            ? url.pathname.slice(1)
            : url.searchParams.get("v");
        const searchParams = url.search;
        embedLink = `https://www.youtube.com/embed/${videoId}${searchParams}`;
      } else if (url.host === "vimeo.com") {
        // For Vimeo
        const videoId = url.pathname.slice(1); // Vimeo video ID is in the path
        embedLink = `https://player.vimeo.com/video/${videoId}`;
      }

      console.log(embedLink, "<==========embedLink");
      return embedLink;
    } catch (error) {
      console.error("Error extracting video information:", error);
      return "";
    }
  }
  useEffect(() => {
    dispatch(getmedia({ id, page, pageSize }));
  }, [page, pageSize]);

  useEffect(() => {
    if (deleteError) {
      message.error(deleteError);
      dispatch(clearState());
    }
    if (deleteSuccess) {
      message.success(deleteSuccess);
      dispatch(clearState());
    }
  }, [deleteError, deleteSuccess]);
  return (
    <Fragment>
      <div className="container-fluid qrr">
        <div className="row noMArr fform">
          <div className="col-sm-12 topAPd widthSeventy">
            <div className="row mrginNo">
              <div className="col-sm-6 formRight">
                <p>{t("gallery")}</p>
              </div>
              {isAuthenticated == "true" && (
                <div className="col-sm-6 formRight">
                  <Button onClick={handleShow}>{t("add")}</Button>
                </div>
              )}
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry gutter="20px">
                  {data?.items?.map((item, i) => (
                    <div className="col-sm-3 imageorvidep" key={i}>
                      <div className="fullImage">
                        {item?.Media ? (
                          <div className="descPhoto">
                            <Image src={item.Media} alt="image" />
                          </div>
                        ) : (
                          <iframe
                            width="100%"
                            height="100%"
                            src={getEmbedLink(item.VideoUrl)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="YouTube video"
                            autoPlay
                          ></iframe>
                        )}
                        {isAuthenticated == "true" && (
                          <div className="overlay">
                            <div className="text">
                              <Tooltip placement="top" title="View Description">
                                <EyeOutlined
                                  className="descButton"
                                  onClick={() => handleShowDescription(item)}
                                />
                              </Tooltip>
                              <Tooltip placement="top" title="Edit">
                                <EditOutlined
                                  onClick={() => handleShowEdit(item)}
                                  className="edditt"
                                />
                              </Tooltip>
                              <Tooltip placement="top" title="Delete">
                                <DeleteOutlined
                                  onClick={() => handleModalOpen(item?._id)}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
              {isAuthenticated == "true" && (
                <div className="col-sm-12 imageLimit">
                  {data && data?.imageCount > 0 ? (
                    <p>
                      {data?.imageCount} {t("imageCount")}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {/* <div className="col-sm-12 packageeUpdation"></div> */}
              {data?.totalCount > 10 && (
                <Pagination
                  TotalData={data?.totalCount}
                  handlePageChange={handlePageChange}
                  currentPage={page}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="uploaddVideoPhoto">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UploadPhotoVideoModal handleClose={handleClose} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showEdit}
        onHide={handleCloseEdit}
        className="uploaddVideoPhoto"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <EditVideoModal
            handleCloseEdit={handleCloseEdit}
            editData={editData}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDescription}
        onHide={handleCloseDescription}
        className="uploaddVideoPhoto"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <DescriptionModal
            data={descriptionData}
            handleClose={handleCloseDescription}
          />
        </Modal.Body>
      </Modal>

      <DeleteModal
        handleDelete={handleDelete}
        id={ids}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />
    </Fragment>
  );
};

export default Photoss

{/* {data?.items?.map((item, i) => (
  <div className="col-sm-3 imageorvidep" key={i}>
    <div className="fullImage">
      {item?.Media ? (
        <img src={item.Media} alt="image" />
      ) : item?.Media ? (
        <img src={item.Media} alt="image" />
      ) : item?.VideoUrl?.includes("youtube.com") ? (
        <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${new URL(
            item.VideoUrl
          ).searchParams.get("v")}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video"
          autoPlay
        ></iframe>
      ) : (
        <video controls autoPlay>
          <source src={item?.VideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      <div className="overlay">
        <div className="text">
          <EditOutlined
            onClick={() => handleShowEdit(item)}
            className="edditt"
          />
          <DeleteOutlined
            onClick={() => handleModalOpen(item?._id)}
          />
        </div>
      </div>
    </div>
  </div>
))} */}