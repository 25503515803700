import React, { useState } from "react";
import { Button, Input, Upload, Form, message } from "antd";
import { UploadOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { postTributeImage } from "../../../Redux/Actions/tributes";

const AddImageInTribute = ({
  isAuthenticated,
  postData,
  t,
  tributeImageUpload,
  AllowToUploadPicture,
  handleCloseImage,
}) => {
  const [imageFields, setImageFields] = useState([{ id: 1 }]);
  const dispatch = useDispatch();
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [form] = Form.useForm();

  const handleRemoveField = (id) => {
    setImageFields(imageFields.filter((field) => field.id !== id));
  };

  const handleAddField = async (index) => {
    setDisabledButtons([...disabledButtons, index]);

    // Validate the description field before proceeding
    try {
      const values = await form.validateFields([`file${index}`]);
      const valuesDesc = await form.validateFields([
        `imageDescription${index}`,
      ]);

      const description = values[`file${index}`];
      const imageDesc = valuesDesc[`imageDescription${index}`];

      if (!description) {
        message.warning("Please add an Image");
        setDisabledButtons(disabledButtons.filter((i) => i !== index));
        return;
      }

      if (imageFields.length < 20) {
        const newId =
          imageFields.length > 0
            ? imageFields[imageFields.length - 1].id + 1
            : 1;
        setImageFields([...imageFields, { id: newId }]);
      } else {
        message.warning("You can add up to 20 images.");
        setDisabledButtons(disabledButtons.filter((i) => i !== index));
        return;
      }

      // Validate the file field
      const fileValues = await form.validateFields([`file${index}`]);

      const formData = new FormData();
      formData.append("Description", imageDesc);

      const file = fileValues[`file${index}`];
      const files = file?.fileList[0]?.originFileObj;

      if (file) {
        formData.append("file", files);
      }

      const id = postData._id;
      dispatch(postTributeImage({ id, formData }));
    } catch (error) {
      console.error("Validation failed:", error);
      setDisabledButtons(disabledButtons.filter((i) => i !== index));
    }
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <div className="col-sm-12 uploadHeading neqwFOrm">
          <p>{t("Add Images")}</p>
        </div>
        {isAuthenticated && postData ? (
          <div className="col-sm-12 addPicture">
            {imageFields.map((field, index) => (
              <div className="row neqwFOrm" key={field.id}>
                <div className="col-sm-4 uploadPIc">
                  <Form.Item
                    name={`file${index}`}
                    label={`${t("image")} ${index + 1}`}
                    rules={[
                      { required: true, message: t("please_upload_image") },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      accept="image/*"
                      maxCount={1}
                      listType="picture-card"
                    >
                      <Button icon={<UploadOutlined />}>
                        {t("click_to_upload")}
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>

                <div className="col-sm-4 imageDescription">
                  <Form.Item
                    name={`imageDescription${index}`}
                    label={t("description")}
                  >
                    <Input placeholder={t("description")} />
                  </Form.Item>
                </div>

                <div className="col-sm-2 svgBtn">
                  <Form.Item label={t("add")}>
                    <Button
                      type="primary"
                      onClick={() => handleAddField(index)}
                      disabled={
                        disabledButtons.includes(index) ||
                        imageFields.length >= 20
                      }
                    >
                      {t("add more images")} <PlusOutlined />
                    </Button>
                  </Form.Item>
                </div>

                <div className="col-sm-2 svgBtn">
                  {imageFields.length > 1 && (
                    <></>
                    // <Form.Item label={t("remove")}>
                    //   <Button
                    //     type="primary"
                    //     onClick={() => handleRemoveField(field.id)}
                    //   >
                    //     {t("remove")} <MinusOutlined />
                    //   </Button>
                    // </Form.Item>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          postData &&
          tributeImageUpload &&
          AllowToUploadPicture && (
            <div className="col-sm-12 addPicture">
              {imageFields.map((field, index) => (
                <div className="row" key={field.id}>
                  <div className="col-sm-4 uploadPIc">
                    <Form.Item
                      name={`file${index}`}
                      label={`${t("image")} ${index + 1}`}
                      rules={[
                        { required: true, message: t("please_upload_image") },
                      ]}
                    >
                      <Upload
                        beforeUpload={() => false}
                        accept="image/*"
                        maxCount={1}
                        listType="picture-card"
                      >
                        <Button icon={<UploadOutlined />}>
                          {t("click_to_upload")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>

                  <div className="col-sm-4 imageDescription">
                    <Form.Item
                      name={`imageDescription${index}`}
                      label={t("description")}
                    >
                      <Input placeholder={t("description")} />
                    </Form.Item>
                  </div>

                  <div className="col-sm-2 svgBtn">
                    <Form.Item label={t("add")}>
                      <Button
                        type="primary"
                        onClick={() => handleAddField(index)}
                        disabled={
                          disabledButtons.includes(index) ||
                          imageFields.length >= 20
                        }
                      >
                        {t("add more images")} <PlusOutlined />
                      </Button>
                    </Form.Item>
                  </div>

                  <div className="col-sm-2 svgBtn">
                    {imageFields.length > 1 && (
                      <></>
                      // <Form.Item label={t("remove")}>
                      //   <Button
                      //     type="primary"
                      //     onClick={() => handleRemoveField(field.id)}
                      //   >
                      //     {t("remove")} <MinusOutlined />
                      //   </Button>
                      // </Form.Item>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )
        )}
        <div className="col-sm-12 submitt neqwFOrm">
          <Button onClick={handleCloseImage} type="primary">
            {t("close")}{" "}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddImageInTribute;
